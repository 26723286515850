import React from 'react'
import favicon from "../assets/images/favicon.webp"

const Fallback = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center custom-background-color" style={{height:"100vh"}}>
        <img className="animate__animated animate__bounce" width={150} src={favicon} alt=""/>
    </div>
  )
}

export default Fallback