import { Button, Card, Col, Drawer, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
// import logowhite from '../assets/images/logonew.svg';
// import logoColor from '../assets/images/logo.png';
// import viewmore from '../assets/images/view-more.png';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { routes } from '../controller/routes';
import arrowdrop from '../assets/images/arrowdrop.png';
import { images } from '../controller/images';
const Navbar = ({ scroll }) => {
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selected, setSelected] = useState('');
  const handleListItemMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleListItemMouseLeave = () => {
    setHoveredItem(null);
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const scrollheader = ['blog', 'case-study', 'contact-us', 'career'];

  useEffect(() => {
    setSelected(
      window.location.pathname.split('/')[
        window.location.pathname.split('/').length - 1
      ]
    );
  }, []);

  const handleClick = (val) => {
    let routeString = val?.keyPath?.reverse().join('/');
    navigate('/' + routeString);
  };
  return (
    <div
      className={`m-0 top-0  w-100 d-flex justify-content-between align-items-center position-fixed custom-navbar-container z-1 ${
        (scroll ||
          scrollheader.includes(window.location?.pathname?.split('/')[1])) &&
        'bg-white border-bottom navbar-scroll-view'
      }`}
    >
      {scroll ||
      scrollheader.includes(window.location?.pathname?.split('/')[1]) ? (
        <img
          src={images?.danipcolorlogo}
          alt=''
          className='logo-img'
          onClick={() => navigate(routes?.home?.self)}
        />
      ) : (
        <img
          src={images?.danipwhitelogo}
          alt=''
          className='logo-img'
          onClick={() => navigate(routes?.home?.self)}
        />
      )}

      <div
        className={` gap-4 justify-content-center align-items-center  header-list ${
          window.location.pathname === '/mobile-app-development-dubai'
            ? 'd-none'
            : 'd-flex'
        }`}
      >
        <ul
          className={`list-unstyled gap-4 justify-content-center align-items-center d-lg-flex d-xl-flex d-md-none d-sm-none d-none m-0 fs-6 fw-normal custom-roman-font ${
            scroll ||
            scrollheader.includes(window.location?.pathname?.split('/')[1])
              ? 'custom-black-color'
              : 'tex-white'
          }`}
        >
          <li
            onMouseEnter={() => handleListItemMouseEnter('/')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === '/' || window.location.pathname === '/'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.self)}
          >
            Home
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('IT Services')}
            className={` d-flex align-items-center gap-1 ${
              hoveredItem === 'IT Services' ||
              window.location.pathname?.includes('it-services')
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
          >
            IT Services{' '}
            <img src={arrowdrop} alt='' className='mt-1' width={10} />
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('Industries')}
            className={`d-flex align-items-center gap-1 ${
              hoveredItem === 'Industries' ||
              window.location.pathname?.includes('technologies')
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
          >
            Industries
            <img src={arrowdrop} alt='' className='mt-1 ' width={10} />
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('Technologies')}
            className={`d-flex align-items-center gap-1 ${
              hoveredItem === 'Technologies' ||
              window.location.pathname?.includes('technologies')
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
          >
            Technologies{' '}
            <img src={arrowdrop} alt='' className='mt-1' width={10} />
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('Case Study')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === 'Case Study' || selected === 'case-study'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.caseStudy?.self)}
          >
            Case Study
          </li>
          {/* <li
            onMouseEnter={() => handleListItemMouseEnter('Workshop')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === 'Workshop' || selected === 'workshop'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.workshop)}
          >
            Workshop
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('Career')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === 'Career' || selected === 'career'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.career)}
          >
            Career
          </li> */}
          <li
            onMouseEnter={() => handleListItemMouseEnter('About Us')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === 'About Us' || selected === 'about-us'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.aboutus)}
          >
            About Us
          </li>
          <li
            onMouseEnter={() => handleListItemMouseEnter('Blog')}
            onMouseLeave={() => handleListItemMouseEnter('')}
            className={`${
              hoveredItem === 'Blog' || selected === 'blog'
                ? 'opacity-100 active'
                : ' opacity-75'
            }`}
            onClick={() => navigate(routes?.home?.blog)}
          >
            Blog
          </li>
        </ul>
        <Button
          size='large'
          className='rounded-5 fs-6 get-in-touch-btn p-4 pt-2 pb-2  fw-medium d-lg-flex d-xl-flex d-md-none d-sm-none d-none custom-roman-font justify-content-center align-items-center'
          onClick={() => navigate(routes?.home?.contactus)}
        >
          Get in Touch
        </Button>
        <MenuOutlined
          className='d-lg-none d-xl-none d-md-flex d-sm-flex d-flex'
          style={
            scroll ||
            scrollheader.includes(window.location?.pathname?.split('/')[1])
              ? { fontSize: 35, color: 'black' }
              : { fontSize: 35, color: 'white' }
          }
          onClick={showDrawer}
        />
      </div>
      {hoveredItem === 'IT Services' && (
        <Card
          style={{
            borderColor: 'black',
            top: '105px',
            width: '90%',
            height: 'auto',
          }}
          onMouseLeave={() => handleListItemMouseLeave()}
          className={`position-absolute p-0 header-drop-down-card z-1 rounded-4 border-0 overflow-hidden ${
            (scroll ||
              scrollheader.includes(
                window.location?.pathname?.split('/')[1]
              )) &&
            'bg-light'
          }`}
        >
          <div className='row col-12 h-100 m-0' style={{ minHeight: '60vh' }}>
            <div className='col-3 p-0'>
              <div className='h-100 sub-header-dropdown-menu-img d-flex  justify-content-center '>
                <div
                  className='d-flex flex-column gap-3 text-white mt-2'
                  style={{ padding: '30px 15px' }}
                >
                  <span className='fs-4 fw-bolder custom-font'>
                    IT Services
                  </span>
                  <span className='fs-6 custom-roman-font'>
                    Your Trusted and Experienced Partner for Driving Continuous
                    Advancement and Innovation in Information Technology
                    Solutions and Services.
                  </span>
                  {/* <img src={viewmore} alt='' width={173} /> */}
                </div>
              </div>
            </div>
            <div className='col-9'>
              <Row gutter={[12, 0]} className='mt-5  sub-item '>
              <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'bim' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.itservices?.bim)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.bim_Icon}
                              height={30}
                              width={30}
                              alt='Mobile development icon'
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>
                            Building Information Modeling (BIM)
                          </h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            The BIM innovation will be well used to promote
                            collaboration among architects, engineers, and
                            contractors, and to optimize design and construction
                            processes.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'mobile-app-development'
                          ? 'opacity-100'
                          : 'opacity-75'
                      }`}
                      onClick={() =>
                        navigate(routes?.itservices?.mobileAppDevelopmet)
                      }
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.mobiledevelopmenticon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>
                            Mobile App Development
                          </h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            We build intuitive IoS and Android mobile apps using
                            the trusted tech stack.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'digital-transformation'
                          ? 'opacity-100'
                          : 'opacity-75'
                      }`}
                      onClick={() =>
                        navigate(routes?.itservices?.digitaldransformation)
                      }
                    >
                      {/* Digital Transformation */}
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.digitaltransformationIConconnew}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>
                            Digital Transformation
                          </h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            We use cutting-edge technologies to design and build
                            a comprehensive tech solution to digitize and
                            transform the business process.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'web-development'
                          ? 'opacity-100'
                          : 'opacity-75'
                      }`}
                      onClick={() =>
                        navigate(routes?.itservices?.webDevelopment)
                      }
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.webdevelopmenticon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Web Development</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Web development services to build robust and
                            scalable applications for businesses and startups.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'consulting' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.itservices?.consulting)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.consultingIconnew}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>
                            Consulting Services
                          </h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Our experts identify, develop, and integrate the
                            best technology solutions to maintain and upgrade
                            the IT infrastructure.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'support' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.itservices?.support)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.supportconsulting}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Support</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Get 24/7 support to maintain and upgrade your
                            existing tech infrastructure.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'staffing' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.itservices?.staffing)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.staffingIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Staffing</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Hire our tech resource on an hourly or contractual
                            basis to work on your project remotely.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'edi' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.itservices?.edi)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.enterprise}
                              height={30}
                              width={30}
                              alt='Mobile development icon'
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>EDI</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Setup and Integrate industry-specific CRM to connect
                            OEM with the channel partners to establish and
                            maintain the supply chain.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                
              </Row>
            </div>
          </div>
        </Card>
      )}
      {hoveredItem === 'Industries' && (
        <Card
          style={{
            borderColor: 'black',
            top: '105px',
            width: '90%',
            height: 'auto',
          }}
          onMouseLeave={() => handleListItemMouseLeave()}
          className={`position-absolute p-0 header-drop-down-card z-1 rounded-4 border-0 overflow-hidden ${
            (scroll ||
              scrollheader.includes(
                window.location?.pathname?.split('/')[1]
              )) &&
            'bg-light'
          }`}
        >
          <div className='row col-12 h-100 m-0' style={{ minHeight: '60vh' }}>
            <div className='col-3 p-0'>
              <div className='h-100 sub-header-dropdown-menu-img d-flex  justify-content-center '>
                <div
                  className='d-flex flex-column gap-3 text-white mt-2'
                  style={{ padding: '30px 15px' }}
                >
                  <span className='fs-4 fw-bolder custom-font'>Industries</span>
                  <span className='fs-6 custom-roman-font'>
                    Your Trusted and Experienced Partner for Driving Continuous
                    Advancement and Innovation in Information Technology
                    Solutions and Services.
                  </span>
                  {/* <img src={viewmore} alt='' width={173} /> */}
                </div>
              </div>
            </div>
            <div className='col-9'>
              <Row gutter={[12, 0]} className='mt-5  sub-item '>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'retail' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.retail)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.RetailblackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Retail</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Developing cutting-edge E-commerce Solutions,
                            Mobile-based POS, Bookings, and Reservation systems
                            for physical or digital retailers.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'healthcare' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.healthcare)}
                    >
                      {/* Web Development */}

                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.HealthcareblackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Healthcare</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Our interconnected patient-centric healthcare
                            solutions are designed to improve efficiency while
                            reducing operations costs.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'education' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.education)}
                    >
                      {/* Digital Transformation */}
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.EducationblackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Education</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            We provide an integrated set of eLearning elements,
                            resources, and tools that aid in delivering
                            knowledge and management.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected == 'media' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.media)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.MediablackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Media</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Our software solutions help you create, edit,
                            manage, and distribute diverse media formats, in
                            both professional and creative roles.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                {/* <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected == 'banking' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.banking)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.BankingblackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Banking</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Our tailored mobile banking solutions help users
                            with exclusive and intuitive UI, custom financial
                            management tools, and real-time account access.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected == 'insurance' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.industries?.insurance)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.InsuranceblackblueIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>insurance</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            We develop innovative custom insurance products for
                            InsurTech startups that leverage advanced frameworks
                            and cutting-edge technologies.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col> */}
              </Row>
            </div>
          </div>
        </Card>
      )}

      {hoveredItem === 'Technologies' && (
        <Card
          style={{
            borderColor: 'black',
            top: '105px',
            width: '90%',
            height: 'auto',
          }}
          onMouseLeave={() => handleListItemMouseLeave()}
          className={`position-absolute p-0 header-drop-down-card z-1 rounded-4 border-0 overflow-hidden ${
            (scroll ||
              scrollheader.includes(
                window.location?.pathname?.split('/')[1]
              )) &&
            'bg-light'
          }`}
        >
          <div className='row col-12 h-100 m-0' style={{ minHeight: '60vh' }}>
            <div className='col-3 p-0'>
              <div className='h-100 sub-header-dropdown-menu-img d-flex  justify-content-center '>
                <div
                  className='d-flex flex-column gap-3 text-white mt-2'
                  style={{ padding: '30px 15px' }}
                >
                  <span className='fs-4 fw-bolder custom-font'>
                    Technologies
                  </span>
                  <span className='fs-6 custom-roman-font'>
                    Your Trusted and Experienced Partner for Driving Continuous
                    Advancement and Innovation in Information Technology
                    Solutions and Services.
                  </span>
                  {/* <img src={viewmore} alt='' width={173} /> */}
                </div>
              </div>
            </div>
            <div className='col-9'>
              <Row gutter={[12, 0]} className='mt-5  sub-item '>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'ai' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.technologies?.ai)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.AIICon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Ai</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Revolutionize your business processes with our
                            AI-based solutions that improve decision-making and
                            unlock valuable data insights.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'cloud' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.technologies?.cloud)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.cloudnativeIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>Cloud</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            You can count on us to manage your complete cloud
                            infrastructure and DevOps stack and Get hassle-free
                            cloud operations.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col span={12}>
                  <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
                    <li
                      className={`${
                        selected === 'iot' ? 'opacity-100' : 'opacity-75'
                      }`}
                      onClick={() => navigate(routes?.technologies?.iot)}
                    >
                      <div className='d-flex justify-content-start gap-2'>
                        <div>
                          <div className='custom-background-color p-2 rounded-5'>
                            <img
                              src={images?.iotIcon}
                              height={30}
                              width={30}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className=''>
                          <h6 className=' fw-bold p-0 m-0 '>IOT</h6>
                          <p
                            className='custom-paragraph-color'
                            style={{ fontSize: '14px' }}
                          >
                            Developing feature-rich IoT software applications by
                            utilizing state-of-the-art technology stacks.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      )}
      <Drawer width={'70%'} onClose={onClose} open={open}>
        <Menu
          mode='inline'
          // defaultSelectedKeys={['5']}
          selectedKeys={[selected]}
          className='custom-roman-font'
          onClick={(val) => handleClick(val)}
          items={[
            {
              key: '',
              label: 'Home',
            },
            {
              key: 'it-services',
              label: 'IT Services',
              children: [
                {
                  key: 'consulting',
                  label: 'Consulting',
                },
                {
                  key: 'mobile-app-development',
                  label: 'Mobile App Development',
                },

                {
                  key: 'support',
                  label: 'Support',
                },

                {
                  key: 'staffing',
                  label: 'Staffing',
                },

                {
                  key: 'web-development',
                  label: 'Web Development',
                },

                {
                  key: 'digital-transformation',
                  label: 'Digital Transformation',
                },

                {
                  key: 'edi',
                  label: 'EDI',
                },
                {
                  key: 'bim',
                  label: 'bim',
                },
              ],
            },
            {
              key: 'industries',
              label: 'Industries',
              children: [
                {
                  key: 'retail',
                  label: 'Retail',
                },
                {
                  key: 'healthcare',
                  label: 'Healthcare',
                },

                {
                  key: 'education',
                  label: 'Education',
                },

                {
                  key: 'media',
                  label: 'Media',
                },

                // {
                //   key: 'banking',
                //   label: 'Banking',
                // },

                // {
                //   key: 'insurance',
                //   label: 'Insurance',
                // },
              ],
            },
            {
              key: 'technologies',
              label: 'Technologies',
              children: [
                {
                  key: 'ai',
                  label: 'AI',
                },
                {
                  key: 'cloud',
                  label: 'Cloud',
                },
                {
                  key: 'iot',
                  label: 'Iot',
                },
              ],
            },
            {
              key: 'case-study',
              label: 'Case Study',
            },
            //  {
            //   key: 'workshop',
            //   label: 'Workshop',
            // },
            // {
            //   key: 'career',
            //   label: 'Career',
            // },
            {
              key: 'about-us',
              label: 'About Us',
            },
            {
              key: 'blog',
              label: 'Blog',
            },
          ]}
        />
        <Button
          className='rounded-5 get-in-touch-btn fw-medium mt-3'
          onClick={() => navigate(routes?.home?.contactus)}
        >
          Get in Touch
        </Button>
      </Drawer>
    </div>
  );
};

export default Navbar;
