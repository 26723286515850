import adstrixLogo from '../assets/images/partnerimg/AdstrixLogo.png';
import sab from '../assets/images/partnerimg/SAB.png';
import bmw from '../assets/images/partnerimg/bmw.png';
import doctor_cpr from '../assets/images/partnerimg/doctor_cpr.png';
import hoppingmonk from '../assets/images/partnerimg/hoppingmonk.png';
import stokkd from '../assets/images/partnerimg/stokkd.png';
import mercedez_benzlogo from '../assets/images/partnerimg/mercedez_benzlogo.png';
import prefillLogo from '../assets/images/partnerimg/prefillLogo.png';
import clinetnew from '../assets/images/partnerimg/clinetnew.png';

import amcservices from '../assets/images/whychooseus/AMC Iocn.svg';
import ourexperties from '../assets/images/whychooseus/Our Experience.svg';
import robust from '../assets/images/whychooseus/Roburst Exp Icon.svg';
import safepayment from '../assets/images/whychooseus/Safe Payments.svg';
import saleservices from '../assets/images/whychooseus/After Sales Iocn.svg';
import weeklyUpdate from '../assets/images/whychooseus/Weekly Update Icon.svg';

import mobileappsection from '../assets/images/mobileappsection.png';
import audile from '../assets/images/mobileapp/audile.png';
import digipay from '../assets/images/mobileapp/digipay.png';
import ediskill from '../assets/images/mobileapp/ediskill.png';
import onTab from '../assets/images/mobileapp/onTab.png';
import snapon from '../assets/images/mobileapp/snapon.png';
import uzone from '../assets/images/mobileapp/uzone.png';

import insurance from '../assets/images/transformindustries/Insurance.png';
import e_commerce from '../assets/images/transformindustries/e-commerce.png';
import education from '../assets/images/transformindustries/education.png';
import fitness_and_wellness from '../assets/images/transformindustries/fitness-and-wellness.png';
import food_and_bevrages from '../assets/images/transformindustries/food-abd-bevrages.png';
import healthIcon from '../assets/images/transformindustries/healthIcon.png';
import realEstate from '../assets/images/transformindustries/realEstate.png';
import media from '../assets/images/transformindustries/media.png';
import retail from '../assets/images/transformindustries/retail.png';
import arabian_saudi_man from '../assets/images/arabian-saudi-man.png';

import deployment from '../assets/images/stepsicon/deployment.png';
import design from '../assets/images/stepsicon/design.png';
import developement from '../assets/images/stepsicon/developement.png';
import ideation from '../assets/images/stepsicon/ideation.png';
import testing from '../assets/images/stepsicon/testing.png';

import bussinessconnect from '../assets/images/bussinessconnect.png';
import andoridicon from '../assets/images/andoridicon.png';
import iosicon from '../assets/images/iosicon.png';
import flutterIcon from '../assets/images/flutterIcon.png';
import reacticon from '../assets/images/reacticon.png';

import featureimg from '../assets/images/featureimg.png';
import checkIcon from '../assets/images/checkIcon.png';
import silliconindoa from '../assets/images/silliconindia.png';
import artificialintelligence from '../assets/images/artificial-intelligence.png';
import aboutusboxdesign from '../assets/images/aboutusboxdesign.png';
import techSoluIcon from '../assets/images/techSoluIcon.png';
import consultingicon from '../assets/images/consultingicon.png';
import eletricdataicon from '../assets/images/eletricdataicon.png';
import offshoreicon from '../assets/images/offshoreicon.png';
import partenerimg1 from '../assets/images/partenerimg1.png';
import partenerimg2 from '../assets/images/partenerimg2.png';
import partenerimg3 from '../assets/images/partenerimg3.png';
import uaeicon from '../assets/images/uaeicon.png';
import indiaicon from '../assets/images/indiaIcon.png';
import uaewhite from '../assets/images/uaewhite.svg';
import indiawhite from '../assets/images/indiawhite.svg';
import linkedin from '../assets/images/linkedinicon.png';
import facebook from '../assets/images/facebook.png';
import twitter from '../assets/images/twitter.png';
import instagram from '../assets/images/instagram.png';
import share from '../assets/images/share.png';
import whatsappwhiteicon from '../assets/images/whatsappwhiteicon.png';
import fbwhiteicon from '../assets/images/fbwhiteicon.png';
import twitterwhiteicon from '../assets/images/twitterwhiteicon.png';
import instawhiteicon from '../assets/images/instawhiteicon.png';
import danipwhitelogo from '../assets/images/danipwhitelogo.svg';
import danipcolorlogo from '../assets/images/Danip Logo SVG (Blue).svg';
import AIICon from '../assets/images/industries/AIICon.png';
import HealthcareIcon from '../assets/images/industries/HealthcareIcon.png';
import EcommerceIcon from '../assets/images/industries/EcommerceIcon.png';
import educationICon from '../assets/images/industries/educationICon.png';
import financialServicesIcon from '../assets/images/industries/financialServicesIcon.png';
import mediaIcon from '../assets/images/industries/mediaIcon.png';
import saasIcon from '../assets/images/industries/saasIcon.png';
import iotIcon from '../assets/images/industries/iotIcon.png';
import enterprise from '../assets/images/industries/enterprise.png';
import webandmobileIcon from '../assets/images/industries/webandmobileIcon.png';
import cloudnativeIcon from '../assets/images/industries/cloudnativeIcon.png';
import HealthcareIconwithwhiteandblur from '../assets/images/industries/HealthcareIconwithwhiteandblur.png';
import EcommerceIconwithwhiteandblur from '../assets/images/industries/EcommerceIconwithwhiteandblur.png';
import educationIConwithwhiteandblur from '../assets/images/industries/educationIConwithwhiteandblur.png';
import financialServicesIconwithwhiteandblur from '../assets/images/industries/financialServicesIconwithwhiteandblur.png';
import mediaIconwithwhiteandblur from '../assets/images/industries/mediaIconwithwhiteandblur.png';
import saasIconwithwhiteandblur from '../assets/images/industries/saasIconwithwhiteandblur.png';
// import demoblogimg from '../assets/images/blog/demoblogimg.png';
import webdevelopmenticon from '../assets/images/home/Web Development (1).svg';
import mobiledevelopmenticon from '../assets/images/home/Mobile Development.svg';
import expend_my_business from '../assets/images/digital-transformation/expendmybusiness.png';
import myapps from '../assets/images/digital-transformation/myapps.png';
import talentNation from '../assets/images/digital-transformation/telentnation.png';
import wow_image from '../assets/images/digital-transformation/wow-image.svg';
import processICon1 from '../assets/images/digital-transformation/processICon1.svg';
import cloudSupport from '../assets/images/digital-transformation/cloudSupport.svg';
import enterpriceswebapplication from '../assets/images/digital-transformation/enterpriceswebapplication.svg';
import saasapplication from '../assets/images/digital-transformation/saasapplication.svg';
import productdevelopment from '../assets/images/digital-transformation/productdevelopment.svg';
import rightarrowcauosel from '../assets/images/digital-transformation/rightarrowcauosel.svg';
import microsoftdotnetwhite from '../assets/images/support/Microsoft .Net  Logo (White).webp';
import right from '../assets/images/right.png';
import left from '../assets/images/left.png';
import asp_netLogo from '../assets/images/technologtStack/ASP_NET.png';
import reactLogo from '../assets/images/technologtStack/react.png';
import gradleLogo from '../assets/images/technologtStack/Gradle.png';
import javaLogo from '../assets/images/technologtStack/Java.png';
import kotlinLogo from '../assets/images/technologtStack/Kotlin.png';
import reduxLogo from '../assets/images/technologtStack/Redux.png';
import rxSwiftLogo from '../assets/images/technologtStack/RxSwift.png';
import xamarinLogo from '../assets/images/technologtStack/Xamarin.png';
import anguralLogo from '../assets/images/technologtStack/angural.png';
import jetpackLogo from '../assets/images/technologtStack/jetpack.png';
import microsoft_netLogo from '../assets/images/technologtStack/microsoft_net.png';
import netcoreLogo from '../assets/images/technologtStack/netcore.png';
import obj_cLogo from '../assets/images/technologtStack/objective-c.png';
import swiftLogo from '../assets/images/technologtStack/swift.png';
import swiftuiLogo from '../assets/images/technologtStack/swiftui..png';
import flutterLogo from '../assets/images/technologtStack/flutter.png';
import swiftlintLogo from '../assets/images/technologtStack/swiftlint.png';

import awsLogo from '../assets/images/ourtechnologies/aws-logo.png';
import azurecloudlogo from '../assets/images/ourtechnologies/azurecloudlogo.png';
import chashlogo from '../assets/images/ourtechnologies/icon_C#_Front End Tech_edited.png';
import microsoft_edited from '../assets/images/ourtechnologies/microsoft_edited.png';
import microsoftlogi from '../assets/images/ourtechnologies/microsoftlogi.png';
import sqlServer from '../assets/images/ourtechnologies/sql-server-icon_edited.png';
import vb_net from '../assets/images/ourtechnologies/vb_net.png';

import flower from '../assets/images/ourtechnologies/flower.png';
import autodeskautocadlogo_edited from '../assets/images/ourtechnologies/autodeskautocadlogo_edited.png';
import icon_AngularJS_edited from '../assets/images/ourtechnologies/icon_Angular JS_edited.png';
import autodeskrevitlogo from '../assets/images/ourtechnologies/autodeskrevitlogo.png';
import javalogo from '../assets/images/ourtechnologies/javalogo.png';
import springbootlogo from '../assets/images/ourtechnologies/springbootlogo.png';
import oracleLogo from '../assets/images/ourtechnologies/oracleLogo.png';

import story2020_21 from '../assets/images/ourStory/story2020-21.png';
import story2021_23 from '../assets/images/ourStory/story2021-23.png';
import story2023_24 from '../assets/images/ourStory/story2023-24.png';
import story2015 from '../assets/images/ourStory/story2015.png';

import buildingimg from '../assets/images/aboutusPage/buildinging.jpg';
import stagephoto from '../assets/images/aboutusPage/Stage Photo 1.png';
import award from '../assets/images/aboutusPage/Award of danip.png';
import cirtificate from '../assets/images/aboutusPage/Cirtificate (White) Png.png';
import risingStar from '../assets/images/aboutusPage/rising star award.png';

import digitalRoadmap from '../assets/images/digital-transformation/digitalRoadmap.svg';
import digitalvision from '../assets/images/digital-transformation/digitalvision.svg';
import implementation from '../assets/images/digital-transformation/implementation.svg';
import identifytransform from '../assets/images/digital-transformation/identifytransform.svg';
import scope from '../assets/images/digital-transformation/scope.svg';


import social_media_marketing from '../assets/images/mobileappDevelopment/social-media-marketing.webp';
import mobilebenifitcardbgimg from '../assets/images/mobileappDevelopment/mobileappdevelopemrntsubimg.webp';
import mobileappdevelopemrntsubimg from '../assets/images/mobileappDevelopment/mobileappdevelopemrntsubimg.webp';
import card_1 from '../assets/images/mobileappDevelopment/card_1.webp';
import card_2 from '../assets/images/mobileappDevelopment/card_2.webp';
import card_3 from '../assets/images/mobileappDevelopment/card_3.webp';
import card_4 from '../assets/images/mobileappDevelopment/card_4.webp';
import card_5 from '../assets/images/mobileappDevelopment/card_5.webp';
import card_6 from '../assets/images/mobileappDevelopment/card_6.webp';
import card_7 from '../assets/images/mobileappDevelopment/card_7.webp';
import lifestyle_businessman from '../assets/images/mobileappDevelopment/lifestyle-businessman 1.webp';
import ecommeracecard from '../assets/images/mobileappDevelopment/ecommeracecard.webp';
import educationcard from '../assets/images/mobileappDevelopment/educationcard.webp';
import fitnesscard from '../assets/images/mobileappDevelopment/fitnesscard.webp';
import foodcard from '../assets/images/mobileappDevelopment/foodcard.webp';
import healthcarecard from '../assets/images/mobileappDevelopment/healthcarecard.webp';
import insurancecard from '../assets/images/mobileappDevelopment/insurancecard.webp';
import mediacard from '../assets/images/mobileappDevelopment/mediacard.webp';
import realstatecard from '../assets/images/mobileappDevelopment/realstatecard.webp';
import retailcard from '../assets/images/mobileappDevelopment/retailcard.webp';

import availity24into7 from '../assets/images/webdevelopment/24into7.webp';
import code_effecience from '../assets/images/webdevelopment/code_effecience.webp';
import webdevelopemrntsubimg from '../assets/images/webdevelopment/ewbdevelopemrntsubimg.webp';
import globalresearch from '../assets/images/webdevelopment/globalresearch.webp';
import portrait_young_man_using_laptop from '../assets/images/webdevelopment/portrait-young-man-using-laptop.webp';
import webdevelopment_secondSection from '../assets/images/webdevelopment/webdevelopment_secondSection.webp';

import cardImages from '../assets/images/AI/Rectangle 4414.png';
import workClosely from '../assets/images/AI/workClosely.svg';

import aiSubimg from '../assets/images/AI/ai-sub-sub-img.png';
import Ai_page_photo from '../assets/images/AI/Ai-page-photo.jpg';
import AI_based_warehouse_management from '../assets/images/AI/AI-based-warehouse-management.jpg';
import Cardano_blockchain_platform from '../assets/images/AI/Cardano-blockchain-platform.jpg';
import Closeup_people_wearing from '../assets/images/AI/Closeup-people-wearing.jpg';
import Smart_agriculture_iot from'../assets/images/AI/Smart-agriculture-iot.png';
import vecteezy_smart_cars from '../assets/images/AI/vecteezy_smart-cars.jpg';
import vecteezy_children_girl_touching from '../assets/images/AI/vecteezy_children-girl-touching.png'

import cloudSubimg from '../assets/images/Cloud/cloud-sub-img.png';
import publiccloud from '../assets/images/Cloud/publiccloud.svg';
import privatecloud from '../assets/images/Cloud/privatecloud.svg';
import hybirdcloud from '../assets/images/Cloud/hybirdcloud.svg';
import multicloud from '../assets/images/Cloud/multicloud.svg';
import appliction from '../assets/images/Cloud/appliction.png';
import backup_disaster_recovery from '../assets/images/Cloud/backup-disaster-recovery.png';
import cloud_infrastructure_solution from '../assets/images/Cloud/Cloud-Infrastructure-Solution.png';
import data_solution from '../assets/images/Cloud/data-solution.png';
import plate_form_solution from '../assets/images/Cloud/plate-form-solution.png';
import security_solution from '../assets/images/Cloud/security-solution.png';


import leslie from '../assets/images/clientimg/leslie.png';
import nathan from '../assets/images/clientimg/nathan.png';
import venkata from '../assets/images/clientimg/venkata.png';

import percentleft from '../assets/images/percentage-card-left-bg.png';
import percentright from '../assets/images/percentage-card-right-bg.png';
import homeconsultingdesign from '../assets/images/home-consulting-design.png';
import frame from '../assets/images/Frame.png';

import EHR from '../assets/images/healthcare/Electronic Health Records (EHR) Systems.png';
import mHealth from '../assets/images/healthcare/mHealth App.png';
import remote_patient from '../assets/images/healthcare/Remote Patient Monitoring.png';
import telemedicine from '../assets/images/healthcare/Telemedicine Platforms.png';

import healthplayer from '../assets/images/healthcare/Health Payers.jpg';
import healthprovider from '../assets/images/healthcare/Health Providers.jpg';
import publichealth from '../assets/images/healthcare/Public Health.jpg';
import healthcaresecondsection from '../assets/images/healthcare/Healthcare Page Photo 1 (1).png';

import microsoftArure from '../assets/images/ourtechnologies/microsoftArure.png'

import clock_edited from '../assets/images/support/clock_edited.png'
import envelope_edited from '../assets/images/support/envelope_edited.png'
import technical_support_edited from '../assets/images/support/technical-support_edited.png'
import cloudsupportnew from '../assets/images/support/Cloud Support.svg'
import supportconsulting from '../assets/images/support/Support Consulting.svg'
import hostingandinfrastructure from '../assets/images/support/Hosting and Infrastructure.svg'
import msme from '../assets/images/footer/image 106.png'
import iso from '../assets/images/footer/image 108.png'
import gem from '../assets/images/footer/image 109.png'
import isologo from '../assets/images/footer/Frame 1000001899.webp'
import microsoftwhitelogo from '../assets/images/support/Microsoft White Logo png.webp'
import microsoftazurewhitelogo from '../assets/images/support/Microsoft Azure Logo.webp'
import quoteleft from '../assets/images/staffing/quoteleft.png'
import quoteright from '../assets/images/staffing/quoteright.png'
import staffingsubing from '../assets/images/staffing/staffingsubing.webp'
import quoteimg from '../assets/images/staffing/quoteimg.png'

import temportresource from '../assets/images/staffing/temportresource.png'
import dedicatedremoteteam from '../assets/images/staffing/dedicatedremoteteam.svg'
import offshorerecuirement from '../assets/images/staffing/offshorerecuirement.svg'
import backup from '../assets/images/staffing/backup_edited.png'
import controllcost from '../assets/images/staffing/low-price_edited.png'
import testedtesting from '../assets/images/staffing/testing_edited.png'
import understandingtechnology from '../assets/images/staffing/understanding of technology_edited.png'

import booking_reservations_software_solution from '../assets/images/retail/Booking & Reservations Software Solution.png'
import document_management_software_solution from '../assets/images/retail/Document Management Software Solution 1.png'
import ecommerce_software_solution from '../assets/images/retail/E-commerce Software Solution.png'
import mobile_pos_software_solution from '../assets/images/retail/Mobile POS Software Solution.png'
import retail_img_hero_section from '../assets/images/retail/Retail Image Hero Section.png'
import retail_sub_img from '../assets/images/retail/retail_sub_img.png'
import vecteezy_ai_driven_retail from '../assets/images/retail/vecteezy_ai_driven_retail.png'

import warehousemanagement from '../assets/images/blogImg/warehousemanagement.png';
import forexcurrency from '../assets/images/blogImg/forexcurrency.png';
import insuranceindustry from '../assets/images/blogImg/Insuranceindustry.png';
import virtualcoin from '../assets/images/blogImg/virtualcoin.png';
import Escrow_platform from '../assets/images/casestudy/image (21) 1 (1) (1).png' 
import b2bfullsize from '../assets/images/casestudy/b2bfullsize.png' 

import book_with_green_board_background from '../assets/images/education/book-with-green-board-background (1) 2.png';
import expendeducation from '../assets/images/education/expendeducation.png';
import improvededucation from '../assets/images/education/improvededucation.png';
import increaseengagement from '../assets/images/education/increaseengagement.png';
import personalizelearning from '../assets/images/education/personalizelearning.png';
import educationsebimg from '../assets/images/education/educationsubimg.png';

import checkIconnew from '../assets/images/Union.png';
import staffingIcon from '../assets/images/staffingIcon.svg';
import digitaltransformationIConconnew from '../assets/images/gitaltransformationIConconnew.svg';
import consultingIconnew from '../assets/images/consultingIconnew.svg';
import mobileBankingIcon from '../assets/images/mobile-banking.png';

import AfterSaleswhiteIocn from '../assets/images/iot/AfterSaleswhiteIocn.svg';
import RoburstExpwhiteIcon from '../assets/images/iot/RoburstExpwhiteIcon.svg';
import SafePaymentswhiteIcon from '../assets/images/iot/SafePaymentswhiteIcon.svg';
import ourexpertieswhiteIcon from '../assets/images/iot/ourexpertieswhiteIcon.svg';
import workCloselywhiteIcon from '../assets/images/iot/workCloselywhiteIcon.svg';
import IIOT from '../assets/images/iot/IIOT.svg';
import iot_app_development from '../assets/images/iot/IoT App Development.svg';
import iot_testing_maintenance from '../assets/images/iot/IoT Testing and Maintenance.svg';
import IoT_Wearable from '../assets/images/iot/IoT-Wearable Connectivity Development.svg';
import iotconsulting from '../assets/images/iot/iotconsulting.svg';


import RetailblackblueIcon from '../assets/images/RetailblackblueIcon.svg';
import HealthcareblackblueIcon from '../assets/images/HealthcareblackblueIcon.svg';
import InsuranceblackblueIcon from '../assets/images/InsuranceblackblueIcon.svg';
import EducationblackblueIcon from '../assets/images/EducationblackblueIcon.svg';
import MediablackblueIcon from '../assets/images/MediablackblueIcon.svg';
import BankingblackblueIcon from '../assets/images/BankingblackblueIcon.svg';

import ediSubimage from '../assets/images/edi/edisubimg.webp';
import cost_saving_Icon from '../assets/images/edi/Cost Saving.svg';
import speen_and_accuracy_Icon from '../assets/images/edi/Speed and Accuracy.svg';
import businessStrategyIcon from '../assets/images/edi/businessStrategyIcon.svg';
import BusinessEfficiencyIcon from '../assets/images/edi/BusinessEfficiencyIcon.svg';

import workshopsubimg from '../assets/images/workshop/workshopsubimg.png';
import subfullimg from '../assets/images/workshop/subfullimg.png';
import blackrighticon from '../assets/images/workshop/blackrighticon.png';
import dotnetIcon from '../assets/images/workshop/dotnetIcon.png';
import javascriptIcon from '../assets/images/workshop/javascriptIcon.png';
import reactjsIcon from '../assets/images/workshop/reactjsIcon.png';
import bimsecondsectionimg from '../assets/images/bim/bimsecondsectionimg.png';

import bim_coordination from '../assets/images/bim/bim_coordination.png';
import bim_for_infrastructure from '../assets/images/bim/bim_for_infrastructure.png';
import bim_map_services from '../assets/images/bim/bim_map_services.png';
import bim_model_auditing from '../assets/images/bim/bim_model_auditing.png';
import bim_moduling_services from '../assets/images/bim/bim_moduling_services.png';
import scan_to_bim_services from '../assets/images/bim/scan_to_bim_services.png';
import bim_benefit_img from '../assets/images/bim/bim_benefit_img.webp';
import bim_Icon from '../assets/images/bim/BIM.svg';

import sample_1 from '../assets/images/bim/vecteezy_3d-rendering-of-modern-cozy-house-with-garage-for-sale-or_30806809 1.png';
import sample_2 from '../assets/images/bim/vecteezy_ai-generated-the-value-of-a-housng-market_38804714 1.png';
import sample_3 from '../assets/images/bim/vecteezy_ai-generated-urban-design-innovation-blueprint-outlines_38967239.png';
import sample_4 from '../assets/images/bim/vecteezy_architectural-project-with-blueprints-and-model-of-a-private_30806778 1.png';
import sample_5 from '../assets/images/bim/vecteezy_innovation-in-progress-project-blueprint-design-unfolds-as_33116635.png';
import sample_6 from '../assets/images/bim/vecteezy_structural-elegance-3d-wireframe-construction-sketch_33112889.png';
import sample_7 from '../assets/images/bim/vecteezy_urban-design-essence-building-wireframe-in-3d-artistic_33113778.png';

import Advertising from '../assets/images/media/Advertising.png';
import Audience from '../assets/images/media/Audience.png';
import contentmanagement from '../assets/images/media/contentmanagement.png';
import livestereming from '../assets/images/media/livestereming.png';
import mediamanagement from '../assets/images/media/mediamanagement.png';
export const images = {
  Advertising,
  Audience,
  livestereming,
  contentmanagement,
  mediamanagement,
  sample_1,
  sample_2,
  sample_3,
  sample_4,
  sample_5,
  sample_6,
  sample_7,
  bim_Icon,
  bim_benefit_img,
  bim_coordination,
  bim_for_infrastructure,
  bim_map_services,
  bim_model_auditing,
  bim_moduling_services,
  scan_to_bim_services,
  bimsecondsectionimg,
  dotnetIcon,
  javascriptIcon,
  reactjsIcon,
  blackrighticon,
  subfullimg,
  workshopsubimg,
  BusinessEfficiencyIcon,
  businessStrategyIcon,
  speen_and_accuracy_Icon,
  cost_saving_Icon,
  ediSubimage,
  BankingblackblueIcon,
  MediablackblueIcon,
  EducationblackblueIcon,
  InsuranceblackblueIcon,
  HealthcareblackblueIcon,
  RetailblackblueIcon,
  IIOT,
  iot_app_development,
  iot_testing_maintenance,
  IoT_Wearable,
  iotconsulting,
  workCloselywhiteIcon,
  ourexpertieswhiteIcon,
  SafePaymentswhiteIcon,
  RoburstExpwhiteIcon,
  AfterSaleswhiteIocn,
  mobileBankingIcon,
  consultingIconnew,
  staffingIcon,
  digitaltransformationIConconnew,
  educationsebimg,
  b2bfullsize,
  checkIconnew,
  Escrow_platform,
  book_with_green_board_background,
  expendeducation,
  improvededucation,
  personalizelearning,
  increaseengagement,
  warehousemanagement,
  forexcurrency,
  insuranceindustry,
  virtualcoin,
  adstrixLogo,
  sab,
  bmw,
  doctor_cpr,
  hoppingmonk,
  stokkd,
  mercedez_benzlogo,
  prefillLogo,
  amcservices,
  ourexperties,
  robust,
  safepayment,
  saleservices,
  weeklyUpdate,
  mobileappsection,
  audile,
  digipay,
  ediskill,
  onTab,
  snapon,
  uzone,
  insurance,
  e_commerce,
  education,
  fitness_and_wellness,
  food_and_bevrages,
  healthIcon,
  realEstate,
  media,
  retail,
  arabian_saudi_man,
  deployment,
  design,
  testing,
  developement,
  ideation,
  bussinessconnect,
  andoridicon,
  iosicon,
  flutterIcon,
  reacticon,
  featureimg,
  checkIcon,
  silliconindoa,
  artificialintelligence,
  aboutusboxdesign,
  techSoluIcon,
  consultingicon,
  eletricdataicon,
  offshoreicon,
  partenerimg1,
  partenerimg2,
  partenerimg3,
  uaeicon,
  indiaicon,
  linkedin,
  facebook,
  twitter,
  instagram,
  financialServicesIcon,
  mediaIcon,
  saasIcon,
  educationICon,
  EcommerceIcon,
  AIICon,
  iotIcon,
  enterprise,
  webandmobileIcon,
  cloudnativeIcon,
  HealthcareIcon,
  // demoblogimg,
  share,
  expend_my_business,
  talentNation,
  myapps,
  right,
  left,
  asp_netLogo,
  reactLogo,
  gradleLogo,
  javaLogo,
  kotlinLogo,
  reduxLogo,
  rxSwiftLogo,
  xamarinLogo,
  anguralLogo,
  jetpackLogo,
  microsoft_netLogo,
  netcoreLogo,
  obj_cLogo,
  swiftLogo,
  swiftuiLogo,
  flutterLogo,
  swiftlintLogo,
  wow_image,
  processICon1,
  productdevelopment,
  saasapplication,
  cloudSupport,
  enterpriceswebapplication,
  rightarrowcauosel,
  awsLogo,
  azurecloudlogo,
  chashlogo,
  microsoft_edited,
  microsoftlogi,
  sqlServer,
  vb_net,
  flower,
  autodeskautocadlogo_edited,
  icon_AngularJS_edited,
  autodeskrevitlogo,
  javalogo,
  springbootlogo,
  oracleLogo,
  story2015,
  story2020_21,
  story2021_23,
  story2023_24,
  buildingimg,
  stagephoto,
  award,
  risingStar,
  cirtificate,
  digitalRoadmap,
  digitalvision,
  implementation,
  identifytransform,
  scope,
  social_media_marketing,
  mobilebenifitcardbgimg,
  mobileappdevelopemrntsubimg,
  card_1,
  card_2,
  card_3,
  card_4,
  card_5,
  card_6,
  card_7,
  lifestyle_businessman,
  retailcard,
  realstatecard,
  mediacard,
  insurancecard,
  healthcarecard,
  foodcard,
  fitnesscard,
  educationcard,
  ecommeracecard,
  availity24into7,
  portrait_young_man_using_laptop,
  code_effecience,
  webdevelopment_secondSection,
  webdevelopemrntsubimg,
  globalresearch,
  cardImages,
  workClosely,
  aiSubimg,
  cloudSubimg,
  publiccloud,
  privatecloud,
  hybirdcloud,
  multicloud,
  Ai_page_photo,
  AI_based_warehouse_management,
  Cardano_blockchain_platform,
  Closeup_people_wearing,
  Smart_agriculture_iot,
  vecteezy_children_girl_touching,
  vecteezy_smart_cars,
  security_solution,
  plate_form_solution,
  data_solution,
  cloud_infrastructure_solution,
  backup_disaster_recovery,
  appliction,
  leslie,
  nathan,
  venkata,
  financialServicesIconwithwhiteandblur,
  mediaIconwithwhiteandblur,
  saasIconwithwhiteandblur,
  educationIConwithwhiteandblur,
  EcommerceIconwithwhiteandblur,
  HealthcareIconwithwhiteandblur,
  percentleft,
  percentright,
  homeconsultingdesign,
  frame,
  EHR,
  mHealth,
  remote_patient,
  telemedicine,
  healthplayer,
  healthprovider,
  publichealth,
  healthcaresecondsection,
  microsoftArure,
  technical_support_edited,
  clock_edited,
  envelope_edited,
  hostingandinfrastructure,
  supportconsulting,
  cloudsupportnew,
  instawhiteicon,
twitterwhiteicon,
fbwhiteicon,
whatsappwhiteicon,
msme,
iso,
gem,
uaewhite,
indiawhite,
danipwhitelogo,
webdevelopmenticon,
mobiledevelopmenticon,
microsoftdotnetwhite,
microsoftwhitelogo,
microsoftazurewhitelogo,
quoteleft,
quoteright,
staffingsubing,
quoteimg,
offshorerecuirement,
dedicatedremoteteam,
temportresource,
understandingtechnology,
testedtesting,
controllcost,
backup,
danipcolorlogo,
retail_img_hero_section,
mobile_pos_software_solution,
ecommerce_software_solution,
document_management_software_solution,
booking_reservations_software_solution,
retail_sub_img,
vecteezy_ai_driven_retail,
isologo,
clinetnew
};
