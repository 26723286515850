import { Col, Divider, Row } from 'antd';
import React from 'react';
import { images } from '../controller/images';
import { useNavigate } from 'react-router-dom';
import { routes } from '../controller/routes';
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div
      className=' p-xl-5 p-lg-5 p-md-4 p-sm-3 p-3 h-auto w-100  text-white '
      style={{  background: '#0E0E0E' }}
    >
      <Row gutter={[12, 15]} justify={'space-evenly'} className='m-0 p-0'>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={8}
          className='d-flex  align-items-center m-0 p-0'
        >
          <img
            src={images.danipwhitelogo}
            alt=''
            style={{ maxWidth: '150px' }}
            className='logo-img  reconised-image'
            // onClick={() => navigate(routes?.home?.self)}
          />
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={16}
          className='d-flex justify-content-end align-items-center m-0 p-0 gap-2'
        >
          <a
            href='https://www.facebook.com/DANIP-103200858456074'
            target='_blank'
            rel='noreferrer'
            aria-label='facebook'
          >
            <img
              loading='lazy'
              src={images.fbwhiteicon}
              alt=''
              className='reconised-image'
            />
          </a>
          <a
            href='https://www.instagram.com/daniptechnologies/'
            target='_blank'
            rel='noreferrer'
            aria-label='instagram'
          >
            <img
              loading='lazy'
              src={images.instawhiteicon}
              alt=''
              className='reconised-image'
            />
          </a>
          <a
            href='https://twitter.com/TechDanip?s=20'
            aria-label='twitter'
            rel='noreferrer'
          >
            <img
              loading='lazy'
              src={images.twitterwhiteicon}
              alt=''
              className='reconised-image'
            />
          </a>
          <a
            href='https://www.linkedin.com/company/danip-technologies/'
            target='_blank'
            rel='noreferrer'
            aria-label='linkedin'
          >
            <img
              loading='lazy'
              src={images.whatsappwhiteicon}
              alt=''
              className='reconised-image'
            />
          </a>
        </Col>
      </Row>
      <Divider style={{ borderColor: 'white' }} />
      <Row
        gutter={[20, 15]}
        className='w-100 footer-list '
        style={{ paddingBottom: 0 }}
      >
        <Col
          xl={4}
          lg={4}
          md={7}
          sm={8}
          xs={24}
          className='d-flex flex-column gap-3'
        >
          <span className='fs-5 fw-bold custom-font'>IT Services</span>
          <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
            <li onClick={() => navigate(routes?.itservices?.consulting)}>
              Consulting
            </li>
            <li onClick={() => navigate(routes?.itservices?.webDevelopment)}>
              Web Development
            </li>
            <li
              onClick={() => navigate(routes?.itservices?.mobileAppDevelopmet)}
            >
              Mobile App Development
            </li>
            <li>Tech Support</li>
            <li>Offshore Staffing</li>
            <li onClick={() => navigate(routes?.itservices?.edi)}>EDI</li>
            <li>Tech Solutions</li>
          </ul>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={7}
          sm={8}
          xs={24}
          className='d-flex flex-column gap-3'
        >
          <span className='fs-5 fw-bold custom-font'>Industries</span>
          <ul className='list-unstyled d-flex flex-column gap-2  custom-roman-font '>
            <li>Insurance</li>
            <li>Retail</li>
            <li onClick={() => navigate(routes?.industries?.healthcare)}>
              Healthcare
            </li>
            <li>Education</li>
            <li>Media</li>
            <li>Banking</li>
          </ul>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={7}
          sm={8}
          xs={24}
          className='d-flex flex-column gap-3'
        >
          <span className='fs-5 fw-bold custom-font'>Technologies</span>
          <ul className='list-unstyled d-flex flex-column gap-2 custom-roman-font'>
            <li onClick={() => navigate(routes?.technologies?.ai)}>Ai</li>
            <li onClick={() => navigate(routes?.technologies?.cloud)}>Cloud</li>
          </ul>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={17}
          sm={17}
          xs={24}
          className='d-flex flex-column gap-3'
        >
          <span className='fs-5 fw-bold custom-font'>Contact Us</span>
          <Row gutter={[20, 20]}>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <div
                className='w-100 p-3  rounded-3  d-flex flex-column gap-2 custom-roman-font h-100'
                style={{ background: '#1C1C1E', opacity: '80%' }}
              >
                <span>
                  <img
                    src={images.uaewhite}
                    alt=''
                    width={50}
                    height={75}
                    loading='lazy'
                  />
                </span>
                <div className='d-flex flex-column gap-1'>
                  <span className='fw-bold'>UAE</span>
                  <span className='fw-normal '>
                    Business Tower - Office No.: 1109 Albarsha1, Business Bay
                  </span>
                  <span>Dubai, UAE</span>
                </div>
                {/* <a
                  href='tel:+971-521858963'
                  className='fs-6 fw-bold'
                  aria-label='+971-521858963'
                  rel='noreferrer'
                >
                  +971-521858963
                </a> */}
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <div
                className='w-100  p-3  rounded-3  d-flex flex-column gap-2 custom-roman-font h-100'
                style={{ background: '#1C1C1E', opacity: '80%' }}
              >
                <span>
                  <img
                    src={images.indiawhite}
                    alt=''
                    width={50}
                    height={75}
                    loading='lazy'
                  />
                </span>
                <div className='d-flex flex-column gap-1'>
                  <span className='fw-bold'>INDIA </span>
                  <span className='fw-normal'>
                    B1/H3, 91 Springboard, Near BMW showroom, Mohan Industrial
                    Estate, New Delhi-110044, India
                  </span>
                </div>
                {/* <a
                  href='tel:+91-9205016667'
                  className='fs-6 fw-bold '
                  aria-label='Call +91-9205016667'
                  rel='noreferrer'
                >
                  +91-9205016667
                </a> */}
              </div>
            </Col>
          </Row>
          <div className='d-flex flex-column gap-1 custom-font'>
            <span className='fw-bold fs-5'>Email-ID </span>
            <a
              href='mailto:Contact@daniptechnologies.com'
              className='fw-normal text-white fs-6'
              rel='noreferrer'
            >
              Contact@daniptechnologies.com
            </a>
          </div>
        </Col>
      </Row>
      <Divider style={{ borderColor: 'white' }} />
      <Row gutter={[12, 15]} justify={'space-evenly'} className='text-center'>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={24}
          className='d-flex  align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-center order-xl-0 order-lg-0 order-md-0 order-sm-0 order-1'
        >
          <span className='custom-font '>
            © 2024 — Copyright | All Rights Reserved
          </span>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={24}
          className='d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-center order-xl-1 order-lg-1 order-md-1 order-sm-1 order-0'
        >
          <div className='w-100 d-flex justify-content-end' style={{ height: '60px' }}>
            <img
              loading='lazy'
              src={images.isologo}
              className='w-auto'
              alt=''
            />
           
          </div>
         
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
