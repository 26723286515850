import React, { Suspense } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { routes } from './controller/routes';
import Fallback from "./components/Fallback";
import BlogDashboard from "./pages/BlogDashboard";
import Blog from "./pages/Blog";
const Home = React.lazy(() => import('./pages/Home'));
const Aboutus = React.lazy(() => import('./pages/Aboutus'));
const DubaiHomePage = React.lazy(() => import('./pages/DubaiHomePage'));
// const BlogDashboard = React.lazy(() => import('./pages/BlogDashboard'));
// const BlogDetail = React.lazy(() => import('./pages/BlogDetail'));
const DigitalTransformation = React.lazy(() =>
  import('./pages/itservices/DigitalTransformation')
);
const Ai = React.lazy(() => import('./pages/technology/Ai'));
const CaseStudy = React.lazy(() => import('./pages/CaseStudy'));
const Consulting = React.lazy(() => import('./pages/itservices/Consulting'));
const Support = React.lazy(() => import('./pages/itservices/Support'));
const Staffing = React.lazy(() => import('./pages/itservices/Staffing'));
const EDI = React.lazy(() => import('./pages/itservices/EDI'));
const WebDevelopment = React.lazy(() =>
  import('./pages/itservices/WebDevelopment')
);
const MobileAppDevelopement = React.lazy(() =>
  import('./pages/itservices/MobileAppDevelopement')
);
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const Retail = React.lazy(() => import('./pages/Industries/Retail'));
const Media = React.lazy(() => import('./pages/Industries/Media'));
const Insurance = React.lazy(() => import('./pages/Industries/Insurance'));
const Healthcare = React.lazy(() => import('./pages/Industries/Healthcare'));
const Education = React.lazy(() => import('./pages/Industries/Education'));
const Banking = React.lazy(() => import('./pages/Industries/Banking'));
const AiWarehouseCaseStudy = React.lazy(() =>
  import('./pages/casestudyView/AiWarehouseCaseStudy')
);
const Cloud = React.lazy(() => import('./pages/technology/Cloud'));
const Career = React.lazy(() => import('./pages/Career'));
const ForexCurrencyCaseStudy = React.lazy(() =>
  import('./pages/casestudyView/ForexCurrencyCaseStudy')
);
const DevelopmentofIntigratingToolcaseStudy = React.lazy(() =>
  import('./pages/casestudyView/DevelopmentofIntigratingToolcaseStudy')
);
const B2bCollaborationsolutioncaseStudy = React.lazy(() =>
  import('./pages/casestudyView/B2bCollaborationsolutioncaseStudy')
);
const EscrowSolutioncaseStudy = React.lazy(() =>
  import('./pages/casestudyView/EscrowSolutioncaseStudy')
);
const VirtualCoinandqrCodecaseStudy = React.lazy(() =>
  import('./pages/casestudyView/VirtualCoinandqrCodecaseStudy')
);
const IOT = React.lazy(() => import('./pages/technology/IOT'));
const WorkShop = React.lazy(() => import('./pages/WorkShop'));
const Bim = React.lazy(() => import('./pages/itservices/Bim'));
function App() {
  return (
    <div className='custom-font'>
      <Suspense  fallback={<Fallback/>}>
        <Routes>
          <Route path={routes.home.self} element={<Home />} />
          <Route
            path={routes.home.mobileAppDevelopmetDubai}
            element={<DubaiHomePage />}
          />
          <Route path={routes.home.aboutus} element={<Aboutus />} />
          <Route path={routes.home.workshop} element={<WorkShop />} />
          <Route path={routes.home.blog} element={<Blog />} />
        {/* <Route path={routes.home.blogdetail} element={<BlogDetail />} /> */}

          <Route path={routes?.home?.caseStudy?.self} element={<CaseStudy />} />
          <Route
            path={routes?.home?.caseStudy?.aiwarehouseCasestudy}
            element={<AiWarehouseCaseStudy />}
          />

          <Route
            path={routes?.home?.caseStudy?.forexcurrencycasestudy}
            element={<ForexCurrencyCaseStudy />}
          />
          <Route
            path={routes?.home?.caseStudy?.integratedToolcasestudy}
            element={<DevelopmentofIntigratingToolcaseStudy />}
          />
          {/* <Route
            path={routes?.home?.caseStudy?.b2bcollaborationplatformcasestudy}
            element={<B2bCollaborationsolutioncaseStudy />}
          /> */}
          <Route
            path={routes?.home?.caseStudy?.escrowsolutioncasestudy}
            element={<EscrowSolutioncaseStudy />}
          />
          <Route
            path={routes?.home?.caseStudy?.virtualcoinandqrprocessingcasestudy}
            element={<VirtualCoinandqrCodecaseStudy />}
          />

          <Route path={routes.home.career} element={<Career />} />
          <Route path={routes.home.contactus} element={<ContactUs />} />

          {/* # itservices */}
          <Route
            path={routes?.itservices?.consulting}
            element={<Consulting />}
          />
          <Route
            path={routes?.itservices?.digitaldransformation}
            element={<DigitalTransformation />}
          />
           <Route
            path={routes?.itservices?.bim}
            element={<Bim />}
          />
          <Route
            path={routes?.itservices?.mobileAppDevelopmet}
            element={<MobileAppDevelopement />}
          />
          <Route
            path={routes?.itservices?.webDevelopment}
            element={<WebDevelopment />}
          />
          <Route path={routes?.itservices?.edi} element={<EDI />} />
          <Route path={routes?.itservices?.staffing} element={<Staffing />} />
          <Route path={routes?.itservices?.support} element={<Support />} />

          {/* #industries */}
          <Route path={routes?.industries?.retail} element={<Retail />} />
          <Route path={routes?.industries?.media} element={<Media />} />
          <Route path={routes?.industries?.insurance} element={<Insurance />} />
          <Route
            path={routes?.industries?.healthcare}
            element={<Healthcare />}
          />
          <Route path={routes?.industries?.education} element={<Education />} />
          <Route path={routes?.industries?.banking} element={<Banking />} />

          {/* #technologies */}
          <Route path={routes.technologies.ai} element={<Ai />} />
          <Route path={routes.technologies.cloud} element={<Cloud />} />
          <Route path={routes.technologies.iot} element={<IOT />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
