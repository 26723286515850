import { include } from 'named-urls';

export const routes = {
  home: include('', {
    self: '/',
    workshop:'workshop',
    caseStudy: include('/case-study', {
      self: '',
      aiwarehouseCasestudy: 'AI-based-Warehouse-Management-Solution',
      forexcurrencycasestudy: 'Forex-Currency-Wallet-Software-Solution',
      integratedToolcasestudy: 'Development-of-Integrated-Tools',
      // b2bcollaborationplatformcasestudy: 'Innovative-B2B-Collaboration-Platform',
     escrowsolutioncasestudy: 'Building-a-Escrow-SolutionTrustworthy',
     virtualcoinandqrprocessingcasestudy: 'Virtual-Coin-and-QR-Processing',
    }),
    aboutus: '/about-us',
    mobileAppDevelopmetDubai: '/mobile-app-development-dubai',
    contactus: '/contact-us',
    career: '/career',
    blog: '/blog',
  }),
  itservices: include('/it-services', {
    consulting: 'consulting',
    mobileAppDevelopmet: 'mobile-app-development',
    support: 'support',
    staffing: 'staffing',
    webDevelopment: 'web-development',
    digitaldransformation: 'digital-transformation',
    edi: 'edi',
    bim:'bim'
  }),
  industries: include('/industries', {
    retail: 'retail',
    healthcare: 'healthcare',
    education: 'education',
    media: 'media',
    banking: 'banking',
    insurance: 'insurance',
  }),
  technologies: include('/technologies', {
    ai: 'ai',
    cloud: 'cloud',
    iot:'iot'
  }),
};
