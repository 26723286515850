import React, { useRef, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Blog = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0);
      function handleScroll() {
        if (window.scrollY >= 56) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      }
  
      window.addEventListener('scroll', handleScroll);
  
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <div>
  <div>
    <HelmetProvider>
    <Helmet>
      <title>
      Danip Technologies - Enabling Digital Transformation
      </title>
      <meta
        name='description'
        content={
          'Danip Technologies provides top-notch Mobile App Development Services in Dubai. Our expert team crafts innovative and user-friendly apps for iOS and Android platforms. Contact us today!'
        }
      />
    </Helmet>
    </HelmetProvider>
    <div className=' w-100'>
      <div className='w-100 position-relative'>
        <Navbar scroll={scroll} />
        <iframe
        id="idMyIframe"
        src="https://daniptechnologies.wixsite.com/danip/blog"
        style={{ width: '100%', height: '100vh', border: 'none',paddingTop:"90px" }}
        title="Blog"
      />
        
        <Footer />
      </div>
    </div>
    </div>
    </div>
  );
};

export default React.memo(Blog);
